<template>
  <div>
    <div
      :id="
        (side === 'left' ? 'item-start-' : 'item-end-') +
        parentName +
        '-' +
        itemKey.toString()
      "
      :class="side === 'left' ? 'tree-node-start' : 'tree-node-end'"
      class="tree-node px-4 py-4"
    >
      <div
        class="tree-node-content d-flex justify-content-between align-items-center"
      >
        <div>
          <i
            class="type-icon mr-1"
            :class="showChildren ? 'fal fa-folder-open' : 'fal fa-folder'"
          />
          <span class="mr-1 text-muted"
            >{{ $t("mapping.collectionEntry") }} {{ itemKey }}</span
          >
        </div>
        <div>
          <button
            class="btn-toggle-children btn btn-circle btn-icon mr-1"
            :class="{ open: showChildren }"
            @click="toggleChildren"
          >
            <i class="fal fa-chevron-down" />
          </button>
          <i
            v-if="side === 'left'"
            class="circle icon-lg fal fa-circle-dot invisible"
          />
        </div>
      </div>
    </div>
    <div
      v-if="children.length && showChildren && !flat"
      class="tree-node-children ml-10"
    >
      <Node
        v-for="(child, i) in children"
        :key="i"
        :field="child"
        :show-fields="showFields"
        :side="side"
        :debug="true"
        :flat="flat"
        :collection-keys="[...collectionKeys, itemKey]"
        @open-modal="e => $emit('open-modal', e)"
        @update-connections="$emit('update-connections')"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Node: () => import("@/components/Mappings/Tree/Node.vue")
  },
  props: {
    itemKey: {
      type: [Number, String],
      default: 0
    },
    item: {
      type: Object,
      default: () => {}
    },
    children: {
      type: Array,
      default: () => []
    },
    showFields: {
      type: Array,
      default: () => []
    },
    side: {
      type: String,
      default: "left"
    },
    parentName: {
      type: String,
      default: ""
    },
    flat: Boolean,
    collectionKeys: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showChildren: false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    toggleChildren() {
      this.showChildren = !this.showChildren;
      this.$nextTick().then(() => this.$emit("update-connections"));
    }
  }
};
</script>
